import React, { useEffect, useState } from 'react';
import Projects from '../components/Projects';
import Hero from '../components/Hero';
import About from '../components/About';
import Service from '../components/Service';
import HomePagdData from '../data/HomePagdData.json';
import Experience from '../components/Experience';
import Contact from '../components/Contact';
import axios from 'axios';

export default function Home() {

  const [data, setData] = useState(HomePagdData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://rushabhsorathiya.com/API/HomePagdData.json');
        setData(response.data);
      } catch (error) {
        setData(HomePagdData);
      }
    };

    fetchData(); // Call the async function
  }, []);

  const {
    hero,
    socialBtns,
    about,
    projects,
    service,
    experience,
    contact,
  } = data;

  return (
    <>
      <Hero data={hero} socialData={socialBtns} />
      <About data={about} />
      <Projects data={projects} />
      <Service data={service} />
      <Experience data={experience} />
      <Contact data={contact} socialData={socialBtns} />
    </>
  );
}
